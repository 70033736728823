import React from 'react';
import './RepartoAct.css';

const integrantes = [
  {
    foto: 'https://firebasestorage.googleapis.com/v0/b/felixvalverdesan.appspot.com/o/butanda.jpg?alt=media&token=475f2438-64a5-4a68-8121-ef27312ad119',
    nombre: 'Butanda Montero Giovani',
    informacion: [
      'Desarrollo del formulario de datos personales y situación académica.',
      'Validaciones en los formularios (asegurar que los campos se completen correctamente).',
      'Implementación del diseño responsive y estilos en la interfaz de usuario.',
      'Optimización de la experiencia de usuario (UI/UX).'
    ]
  },
  {
    foto: 'https://firebasestorage.googleapis.com/v0/b/felixvalverdesan.appspot.com/o/angel.jpg?alt=media&token=a0e5b507-203d-43cb-bcee-0011a105a3d7',
    nombre: 'Elizondo Sandoval Angel Eduardo',
    informacion: [
      'Estructura de la base de datos en Firebase (organización de los datos).',
      'Creación y optimización de las consultas a Firebase.',
      'Implementación de la lógica para el envío y recuperación de datos en los formularios.',
      'Optimización del rendimiento en la carga de datos de Firebase.'
    ]
  },
  {
    foto: 'https://firebasestorage.googleapis.com/v0/b/felixvalverdesan.appspot.com/o/hector1.jpg?alt=media&token=1f279d3a-bbe1-4839-ba4b-180cfaa52667',
    nombre: 'Tabarez Lopez Hector Jesus',
    informacion: [
      'Diseño y desarrollo de las gráficas de barras dinámicas en la vista de administrador.',
      'Configuración de eventos al pasar el cursor sobre las gráficas.',
      'Optimización del número de votantes y correcta asociación con las preguntas.',
      'Asegurar la actualización en tiempo real de las gráficas con Firebase.'
    ]
  },
  {
    foto: 'https://firebasestorage.googleapis.com/v0/b/felixvalverdesan.appspot.com/o/362d7def-1847-4e7c-ac65-cc173a33b2ff.jpg?alt=media&token=1d86c619-2be5-4df4-b7d8-dc983015b497',
    nombre: 'Valverde Sanchez Felix Marlon',
    informacion: [
      'Estructura y funcionalidad general del proyecto en React.',
      'Gestión de Firebase (almacenamiento y recuperación de datos).',
      'Integración del formulario con la base de datos.',
      'Conexión de la vista de administrador con Firebase para extraer datos.',
      'Desarrollo de las gráficas dinámicas en la vista de administrador.',
      'Publicación y mantenimiento en Vercel.',
      'Coordinación del equipo y revisión final del código.'
    ]
  }
];

const RepartoAct = () => {
  return (
    <div className="reparto-container">
      <h1>Lista de integrantes y su aportación</h1>
      <section className="integrantes">
        {integrantes.map((integrante, index) => (
          <div className="contenedor" key={index}>
            <img src={integrante.foto} alt={integrante.nombre} className="foto" />
            <div className="info">
              <h3>{integrante.nombre}</h3>
              <ul>
                {integrante.informacion.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default RepartoAct;
